import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { DimensionDescriptor, FunctionDescription, MeasureDataset } from "../../../../../api/biApi.types";
import { IFormulaVariable, DimensionFormulaVariable, FunctionFormulaVariable } from "./variables";

export const getDimensionVariables = (dimensions: DimensionDescriptor[]): IFormulaVariable[] => {
  return dimensions.map((dimension) => new DimensionFormulaVariable({ ...cloneDeep(dimension) }));
};

export const getFunctionVariables = (functions: FunctionDescription[]): IFormulaVariable[] => {
  return functions.map((func) => new FunctionFormulaVariable(func));
};

export const filterDictionary = (
  dimension: DimensionDescriptor,
  table?: MeasureUnitTable,
  datasets?: MeasureDataset[]
) => {
  if (table === undefined || datasets === undefined) return dimension.dictionary;
  const measureDataSet = datasets.find((ds) => ds.table === table);
  if (measureDataSet === undefined) return dimension.dictionary;
  const values = measureDataSet.values[dimension.name];
  if (values === undefined) return dimension.dictionary;
  return dimension.dictionary.filter((d) => values.indexOf(d[dimension.keyFieldName] || "") > -1);
};

const availableDimensions = ["AccountCategory", "AccountName", "AccountNo"];

export const getAvailableDimensions = (dimensions: DimensionDescriptor[]) => {
  return dimensions.filter((d) => availableDimensions.includes(d.name));
};
