import { AmountType, CalculateByField, MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { fillNodes, FormulaNode, getAllVariableNodes } from "../utilities/fillNodes";
import { INode, NodeFunction, NodeNumber, NodeVariable } from "../utilities/formulaParser";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { CustomMeasureState, NodesCounter } from "./customMeasureState";
import { findNodeByTokenData } from "./useCustomMeasure";
import { DimensionDescriptor, MeasureDataset } from "../../../../../api/biApi.types";
import { ConditionField } from "../../../Types";
import { Token } from "../utilities/tokenizer";
import { filterDictionary } from "../utilities/variables.utils";

export const CustomMeasureReducer = (state: CustomMeasureState, action: Actions): CustomMeasureState => {
  switch (action.type) {
    case CustomMeasureActionType.FORMULA_PARAMETER_CLICK: {
      return { ...state, selectedFormulaParameter: action.payload };
    }
    case CustomMeasureActionType.FUNCTION_HOVERED: {
      return { ...state, functionHovered: action.payload };
    }
    case CustomMeasureActionType.HIGHLIGHT_NODE: {
      if (state.formulaNodes.length === 0) return state;
      const newState = cloneDeep(state);
      const formulaNodes: FormulaNode[] = [];
      newState.formulaNodes.forEach((fn) => {
        let node: FormulaNode | undefined;
        const highlight = fn.linkedNodes.some((ln) => findNodeByTokenData(ln, action.payload));
        node = { ...fn, highlighted: highlight };
        if (node.args !== undefined) {
          const argsFormulaNodes: FormulaNode[] = [];
          node.args.forEach((arg) => {
            const highlight = arg.linkedNodes.some((ln) => findNodeByTokenData(ln, action.payload));
            argsFormulaNodes.push({ ...arg, highlighted: highlight });
          });
          node = { ...node, args: argsFormulaNodes };
        }
        formulaNodes.push(node);
      });
      return { ...newState, formulaNodes: formulaNodes };
    }
    case CustomMeasureActionType.UPDATE: {
      return { ...state, ...action.payload };
    }
    case CustomMeasureActionType.SET_ROOTNODE: {
      const newState = { ...cloneDeep(state), rootNode: action.payload };
      const nodesCounter: NodesCounter = {
        nodeVariable: 0,
        nodeFunction: 0,
        nodeNumber: 0,
      };
      newState.formulaNodes = [];

      if (action.payload === undefined) {
        newState.currentToken = undefined;
      } else {
        const increaseNodesCounter = (node: INode) => {
          if (node instanceof NodeVariable) {
            nodesCounter.nodeVariable++;
          } else if (node instanceof NodeFunction) {
            nodesCounter.nodeFunction++;
          } else if (node instanceof NodeNumber) {
            nodesCounter.nodeNumber++;
          }
        };
        fillNodes(newState.formulaNodes, state.formulaNodes, action.payload, newState.functions, increaseNodesCounter);
      }
      newState.nodesCounter = newState.formula ? nodesCounter : undefined;
      newState.formulaNodes.forEach((fn) => {
        updateDictionaryValuesByDataSet(fn, newState.dimensions, newState.dataSets);
      });

      return newState;
    }
    case CustomMeasureActionType.UPDATE_FORMULA_NODE: {
      const newState = { ...cloneDeep(state) };
      newState.formulaNodes.forEach((fn) => {
        if (fn.function !== undefined && fn.args !== undefined) {
          fn.args.forEach((arg) => {
            if (arg.key === action.payload.node.key) {
              Object.assign(arg, action.payload.changes);
            }
          });
        }
        if (fn.key === action.payload.node.key) {
          Object.assign(fn, action.payload.changes);
        }
      });
      return newState;
    }
    case CustomMeasureActionType.UPDATE_DATASET: {
      const newState: CustomMeasureState = cloneDeep(state);

      const node = getAllVariableNodes(newState.formulaNodes).find((fn) => fn.key === action.payload.key);
      if (node?.table !== undefined && node.table !== action.payload.dataset) {
        node.table = action.payload.dataset;
        clearConditionValue(
          node,
          newState.dimensions.map((d) => d.name)
        );
        updateDictionaryValuesByDataSet(node, newState.dimensions, newState.dataSets);
      }
      return newState;
    }
    case CustomMeasureActionType.UPDATE_DIMENSIONS: {
      const newState: CustomMeasureState = { ...cloneDeep(state), dimensions: action.payload.dimensions };
      newState.formulaNodes.forEach((fn) => {
        updateDictionaryValuesByDataSet(fn, newState.dimensions, newState.dataSets);
      });
      return newState;
    }
    case CustomMeasureActionType.ADD_CONDITIONS: {
      const newState: CustomMeasureState = cloneDeep(state);
      const node = getNode(newState.formulaNodes, action.payload.node);
      if (node !== undefined) {
        node.conditions = (node.conditions || []).concat(action.payload.newConditions);
        updateDictionaryValuesByDataSet(node, newState.dimensions, newState.dataSets);
      }
      return newState;
    }
    case CustomMeasureActionType.UPDATE_CONDITION: {
      const newState: CustomMeasureState = cloneDeep(state);
      const node = getNode(newState.formulaNodes, action.payload.node);
      if (node?.conditions !== undefined) {
        const condition = node.conditions.find((c) => c.meta.name === action.payload.condition.meta.name);
        if (condition !== undefined) {
          Object.assign(condition, action.payload.changes);
        }
        updateDictionaryValuesByDataSet(node, newState.dimensions, newState.dataSets);
      }
      return newState;
    }
    case CustomMeasureActionType.REMOVE_CONDITION: {
      const newState: CustomMeasureState = cloneDeep(state);
      const node = getNode(newState.formulaNodes, action.payload.node);
      if (node?.conditions !== undefined) {
        const index = node.conditions.findIndex((c) => c.meta.name === action.payload.condition.meta.name);
        if (index > -1) {
          node.conditions.splice(index, 1);
          node.conditions = [...node.conditions];
        }
      }
      return newState;
    }
    case CustomMeasureActionType.SET_DATASETS: {
      const newState: CustomMeasureState = cloneDeep(state);
      newState.dataSets = action.payload;
      newState.formulaNodes.forEach((fn) => {
        updateDictionaryValuesByDataSet(fn, newState.dimensions, newState.dataSets);
      });
      return newState;
    }
    default:
      return state;
  }
};
export enum CustomMeasureActionType {
  UPDATE = "UPDATE",
  SET_ROOTNODE = "SET_ROOTNODE",
  UPDATE_FORMULA_NODE = "UPDATE_FORMULA_NODE",
  UPDATE_DATASET = "UPDATE_DATASET",
  UPDATE_DIMENSIONS = "UPDATE_DIMENSIONS",
  ADD_CONDITIONS = "ADD_CONDITIONS",
  UPDATE_CONDITION = "UPDATE_CONDITION",
  REMOVE_CONDITION = "REMOVE_CONDITION",
  HIGHLIGHT_NODE = "HIGHLIGHT_NODE",
  FUNCTION_HOVERED = "FUNCTION_HOVERED",
  FOCUS_FORMULA_INPUT = "FOCUS_FORMULA_INPUT",
  FORMULA_PARAMETER_CLICK = "FORMULA_PARAMETER_CLICK",
  SET_DATASETS = "SET_DATASETS",
}

export type UpdateAction = {
  type: CustomMeasureActionType.UPDATE;
  payload: Partial<CustomMeasureState>;
};

export type UpdateDatasetAction = {
  type: CustomMeasureActionType.UPDATE_DATASET;
  payload: { key: string; dataset: MeasureUnitTable };
};

export type UpdateDimensionsAction = {
  type: CustomMeasureActionType.UPDATE_DIMENSIONS;
  payload: { dimensions: DimensionDescriptor[] };
};

export type SetRootAction = {
  type: CustomMeasureActionType.SET_ROOTNODE;
  payload?: INode;
};

export type UpdateFormulaNodeAction = {
  type: CustomMeasureActionType.UPDATE_FORMULA_NODE;
  payload: { node: FormulaNode; changes: Partial<FormulaNode> };
};

export type AddConditionsAction = {
  type: CustomMeasureActionType.ADD_CONDITIONS;
  payload: { node: FormulaNode; newConditions: ConditionField[] };
};

export type UpdateConditionAction = {
  type: CustomMeasureActionType.UPDATE_CONDITION;
  payload: { node: FormulaNode; condition: ConditionField; changes: Partial<ConditionField> };
};

export type RemoveConditionAction = {
  type: CustomMeasureActionType.REMOVE_CONDITION;
  payload: { node: FormulaNode; condition: ConditionField };
};

export type HighlightNodeAction = {
  type: CustomMeasureActionType.HIGHLIGHT_NODE;
  payload: Token | undefined;
};

export type FunctionHoverAction = {
  type: CustomMeasureActionType.FUNCTION_HOVERED;
  payload: Token | undefined;
};

export type FormulaParameterClickAction = {
  type: CustomMeasureActionType.FORMULA_PARAMETER_CLICK;
  payload: Token | undefined;
};

export type SetDataSetsAction = {
  type: CustomMeasureActionType.SET_DATASETS;
  payload: MeasureDataset[];
};

export type Actions =
  | UpdateAction
  | UpdateDatasetAction
  | UpdateDimensionsAction
  | SetRootAction
  | UpdateFormulaNodeAction
  | AddConditionsAction
  | UpdateConditionAction
  | RemoveConditionAction
  | HighlightNodeAction
  | FunctionHoverAction
  | FormulaParameterClickAction
  | SetDataSetsAction;

export const initialState: CustomMeasureState = {
  formulaNodes: [],
  dimensions: [],
  functions: [],
  caption: "",
  calculateBy: CalculateByField.Lcy,
  amountType: AmountType.Net,
  dataSets: [],
};

export const getNode = (nodes: FormulaNode[], actualNode: FormulaNode) => {
  let node: FormulaNode | undefined;
  const findNode = (fnds: FormulaNode[]) => {
    fnds.forEach((fn) => {
      if (node !== undefined) return;
      if (fn.key === actualNode.key) {
        node = fn;
      } else if (fn.args !== undefined) {
        findNode(fn.args);
      }
    });
  };
  findNode(nodes);
  return node;
};

export const clearConditionValue = (fn: FormulaNode, datasetDimesnions: string[]) => {
  if (fn.function === undefined && fn.conditions !== undefined && fn.conditions.length > 0) {
    fn.conditions.forEach((condition) => {
      if (datasetDimesnions.includes(condition.meta.name)) {
        condition.config.filter.values = [];
      }
    });
  }
};

export const clearConditionValues = (nodes: FormulaNode[], datasetDimesnions: string[]) => {
  const clear = (fn: FormulaNode) => {
    if (fn.function === undefined && fn.conditions !== undefined && fn.conditions.length > 0) {
      fn.conditions.forEach((condition) => {
        if (datasetDimesnions.includes(condition.meta.name)) {
          condition.config.filter.values = [];
        }
      });
    }
    if (fn.function !== undefined && fn.args !== undefined) {
      fn.args.forEach(clear);
    }
  };
  nodes.forEach(clear);
};

export const resetAmountTypeToDefault = (state: CustomMeasureState) => {
  state.amountType = AmountType.Net;
};

const updateDictionaryValuesByDataSet = (
  fn: FormulaNode,
  dimensions: DimensionDescriptor[],
  dataSets: MeasureDataset[]
) => {
  if (dataSets.length === 0) return;
  if (fn.function === undefined && fn.conditions !== undefined && fn.conditions.length > 0) {
    fn.conditions.forEach((condition) => {
      const filter = condition.config.filter;
      if (filter === undefined) return;
      const dimension = dimensions.find((d) => d.name === filter.dimensionName);
      if (dimension !== undefined && dimension.dictionary.length > 0) {
        const dictionary = filterDictionary(dimension, fn.table, dataSets);
        condition.meta.dictionary = dictionary;

        if (!condition.dictionary?.loading && condition.dictionary?.loaded) {
          filter.values = dictionary
            .map((d) => d[dimension.keyFieldName] || "")
            .filter((v) => filter.values.indexOf(v || "") > -1);
        }
      }
    });
  }
  if (fn.function !== undefined && fn.args !== undefined) {
    fn.args.forEach((node) => updateDictionaryValuesByDataSet(node, dimensions, dataSets));
  }
};
