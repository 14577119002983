import { Grid } from "@mui/material";
import { CustomMeasureReturnType } from "../hooks/useCustomMeasure";
import FunctionPropertyItem from "./FunctionPropertyItem";
import VariablePropertyItem from "./VariablePropertyItem";
import { useCallback } from "react";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { FormulaNode } from "../utilities/fillNodes";

interface Props {
  state: CustomMeasureReturnType;
  editable: boolean;
}

export const Properties = ({ state, editable }: Props) => {
  const getChartOfAccountItems = useCallback(
    (node: FormulaNode) => {
      if (node.table === MeasureUnitTable.Memo) {
        return state.chartOfAccounts?.memo;
      }

      return state.chartOfAccounts?.gl;
    },
    [state.chartOfAccounts?.gl, state.chartOfAccounts?.memo]
  );

  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",
        gap: 2,
        overflowY: "auto",
        overflowX: "hidden",
        py: 2,
        pl: 2.5,
        pr: 3,
      }}
    >
      {state.formulaNodes.map((fn, i) => {
        if (fn.function !== undefined) {
          return (
            <FunctionPropertyItem
              key={i}
              formulaNode={fn}
              state={state}
              editable={editable}
              chartOfAccountItems={getChartOfAccountItems(fn)}
            />
          );
        }
        return (
          <VariablePropertyItem
            key={i}
            formulaNode={fn}
            state={state}
            editable={editable}
            chartOfAccountItems={getChartOfAccountItems(fn)}
          />
        );
      })}
    </Grid>
  );
};

export default Properties;
