import { Alert, Divider, Grid } from "@mui/material";
import CollapseContainer from "../../../../common/CollapseContainer";
import { useDrillDownState } from "../../../../../hooks/drillDownState";
import { FieldWithOrder } from "../../../../../hooks/FieldWithOrder";
import ConditionsCollapsedHeader from "../ConditionsCollapsedHeader";
import ColumnsOptions from "../columns/ColumnsOptions";
import { grey } from "@mui/material/colors";
import RecordsCounter from "../RecordsCounter";
import { useDispatch, useSelector } from "react-redux";
import { drillDownActions } from "../../../../../store/drilldownSlice";
import React, { useMemo } from "react";
import ToolBar from "../ToolBar";
import ConditionsPanel from "../ConditionsPanel";
import DrillDownGrid from "../DrillDownGrid";
import { generateGuid } from "../../../../../../shared/utilities/generateGuid";
import useDeferredDictionaryLoading from "../../hooks/useDeferredDictionaryLoading";
import { selectDimensions, selectDimensionsStructure } from "../../../../../store/metaDataSlice";
import { CellDrillDownInfoBase, CellDrillDownInfoType, DimensionDescriptor } from "../../../../../api/biApi.types";
import {
  CalculateByField,
  ConditionEqualityType,
  MeasureUnitTable,
} from "../../../../../../shared/reporting/api/biClient.types";
import { formatDimension } from "../../../../../formatters/NumberFormatter";
import cloneDeep from "../../../../../../shared/utilities/cloneDeep";
import { DrillDownConfiguration } from "../../../../../store/DrillDownConfigurationState";

interface Props {
  configuration: DrillDownConfiguration;
  info: CellDrillDownInfoBase;
}

export default function LedgerPart({ configuration, info }: Props) {
  const dimensions = useSelector(selectDimensions);
  const dimensionsStructure = useSelector(selectDimensionsStructure);

  const state = useDrillDownState(info);
  const dispatch = useDispatch();

  const [showColumnsOptions, setShowColumnsOptions] = React.useState<boolean | undefined>();
  const filteredDimensions = useMemo(
    () => (info.table === MeasureUnitTable.Gl ? dimensions : dimensions.filter((d) => !glTableFields.includes(d.name))),
    [dimensions, info.table]
  );

  const withAllocation = React.useMemo(() => info.allocated === true, [info.allocated]);
  useDeferredDictionaryLoading({ values: state.conditions, updateItem: state.actions.updateCondition });

  React.useEffect(() => {
    const fields = getDefaultFields(withAllocation, filteredDimensions);
    state.actions.changeSelectedFields(fields);
    const entryNoField = fields.find((f) => f.field.name === "EntryNo");
    if (entryNoField) {
      state.actions.changeFieldSorting([{ field: entryNoField.field, sortAsc: true, order: 0 }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredDimensions, withAllocation]);

  const handleSubDrillDown = React.useCallback(
    (row: Record<string, string>) => {
      const newConfiguration = createSubDrillDown(row, configuration, info, filteredDimensions);
      if (newConfiguration !== undefined) dispatch(drillDownActions.add(newConfiguration));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredDimensions, configuration, state.conditions]
  );

  const hideColumnsOptions = React.useCallback(() => setShowColumnsOptions(false), []);

  return (
    <Grid container sx={{ position: "relative", display: "flex", flex: 1, flexDirection: "row" }}>
      <CollapseContainer collapseElement={<ConditionsCollapsedHeader conditionsCount={state.conditions.length} />}>
        <ConditionsPanel state={state} configuration={configuration} info={info} />
      </CollapseContainer>
      <Grid container sx={{ flex: 1, width: "unset", flexDirection: "column" }}>
        <Grid container sx={{ flex: 1, width: "unset", flexDirection: "column", pl: "1.3rem", py: 2, pr: 3, gap: 2 }}>
          <ToolBar state={state} onShowColumnsOptions={() => setShowColumnsOptions(true)} />
          {state.gridState.error && (
            <Alert sx={{ mb: ".5rem" }} severity="error">
              {state.gridState.error}
            </Alert>
          )}
          <DrillDownGrid state={state} configuration={configuration} info={info} onDrillDown={handleSubDrillDown} />
        </Grid>
        <Divider />
        <Grid container sx={() => ({ backgroundColor: grey[50] })}>
          <RecordsCounter
            loading={false}
            skip={state.gridState.state.skip}
            take={state.gridState.state.take}
            totalCount={state.gridState.state.totalCount}
          />
        </Grid>
      </Grid>
      {showColumnsOptions !== undefined && (
        <ColumnsOptions
          dimensions={filteredDimensions}
          selectedFields={state.fields}
          sortedFields={state.sortedFields}
          groupStructure={dimensionsStructure}
          open={showColumnsOptions === true}
          withAllocation={withAllocation}
          onApplySelection={state.actions.changeSelectedFields}
          onApplySorting={state.actions.changeFieldSorting}
          onClose={hideColumnsOptions}
        />
      )}
    </Grid>
  );
}

const glTableFields = ["CreditLcy", "CreditPcy", "DebitLcy", "DebitPcy"];

function getDefaultFields(withAllocation: boolean, allFields: DimensionDescriptor[]) {
  const onlyAllocatedDimensions = ["InvestorTypeCode", "InvestorName", "Shares", "TransactionNo"];
  const defaultFields = [
    "EntryNo",
    "TransactionType",
    "TransactionNo",
    "EntityName",
    "InvestorTypeCode",
    "InvestorName",
    "PostingDate",
    "TransactionCode",
    "AccountNo",
    "AccountName",
    "AccountCategory",
    "EntryDescription",
    "InvestmentName",
    "PositionType",
    "TaxLotId",
    "DealDescription",
    "AmountLcy",
    "AmountPcy",
    ...glTableFields,
    "Shares",
    "CreatedAt",
    "CreatedBy",
    "TransactionReversed",
  ];

  return defaultFields
    .filter((f) => (withAllocation ? true : !onlyAllocatedDimensions.includes(f)))
    .map<FieldWithOrder | undefined>((name, index) => {
      const field = allFields.find((f) => f.name === name);
      if (field) {
        return { field, order: index, canBeRemoved: name !== "EntryNo" };
      }
      return undefined;
    })
    .filter((item): item is FieldWithOrder => !!item);
}

function createSubDrillDown(
  row: Record<string, string>,
  configuration: DrillDownConfiguration,
  info: CellDrillDownInfoBase,
  dimensions: DimensionDescriptor[]
) {
  const ledgerEntryNoDimension = dimensions.find((d) => d.name === "LedgerEntryNo");
  const entryNo = row["EntryNo"];

  if (entryNo === undefined || ledgerEntryNoDimension === undefined) return undefined;

  const value = getSubDrillDownFormattedValue(info, row, dimensions);
  const measure = getSubDrillDownMeasure(configuration, row);

  const newConfiguration: DrillDownConfiguration = {
    id: generateGuid(),
    measure,
    cell: {
      value: undefined,
      formattedValue: value || "",
    },
    info: {
      combined: {
        drillDownInfoType: CellDrillDownInfoType.Default,
        field: info.field || CalculateByField.Lcy,
        table: info.table || MeasureUnitTable.Gl,
        allocated: true,
        conditions: [
          {
            dimensionName: ledgerEntryNoDimension.name,
            equalityType: ConditionEqualityType.Equal,
            values: [entryNo],
          },
        ],
        amountType: info.amountType,
      },
      items: [
        {
          drillDownInfoType: CellDrillDownInfoType.Default,
          field: info.field || CalculateByField.Lcy,
          table: info.table || MeasureUnitTable.Gl,
          allocated: true,
          conditions: [
            {
              dimensionName: ledgerEntryNoDimension.name,
              equalityType: ConditionEqualityType.Equal,
              values: [entryNo],
            },
          ],
          amountType: info.amountType,
        },
      ],
    },
    onlyLedger: true,
  };

  return newConfiguration;
}

function getSubDrillDownFormattedValue(
  info: CellDrillDownInfoBase,
  row: Record<string, string>,
  dimensions: DimensionDescriptor[]
) {
  const valueFieldMapping = {
    [CalculateByField.Lcy]: "AmountLcy",
    [CalculateByField.Pcy]: "AmountPcy",
    [CalculateByField.Shares]: "Shares",
    [CalculateByField.AdditionalLcy]: "AdditionalAmountLcy",
  };
  const valueField = valueFieldMapping[info?.field || CalculateByField.Lcy];
  let value = row[valueField];
  const valueDimension = dimensions.find((d) => d.name === valueField);
  if (valueDimension !== undefined) {
    value = formatDimension(valueDimension, value);
  }
  return value;
}
function getSubDrillDownMeasure(configuration: DrillDownConfiguration, row: Record<string, string>) {
  const measure = cloneDeep(configuration.measure);
  if (measure !== undefined) {
    measure.config.customLabel = getSubDrillDownMeasureCaption(row);
  }

  return measure;
}
function getSubDrillDownMeasureCaption(row: Record<string, string>) {
  const accountNo = row["AccountNo"];
  const accountName = row["AccountName"];
  if (accountNo && accountName) {
    return `${accountNo} - ${accountName}`;
  }

  return accountNo || accountName || "";
}
