import { Box, Checkbox, Divider, Grid, SxProps, Theme, Typography } from "@mui/material";
import { Fragment, useEffect, useMemo, useRef } from "react";
import { CustomMeasureReturnType, findNodeByTokenData } from "../../customMeasure/hooks/useCustomMeasure";
import { FormulaNode } from "../../customMeasure/utilities/fillNodes";
import useDeferredDictionaryLoading from "../../hooks/useDeferredDictionaryLoading";
import { ChartOfAccountsContextItem, useChartOfAccountsContext } from "../contexts/ChartOfAccountsContext";
import { getVariablePropertyItemUniqueId } from "../helpers/chartOfAccountsContextHelper";
import Filter from "./Filter";
import PropertyItemContainer from "./PropertyItemContainer";
import { PropertyName } from "./PropertyName";
import "./VariablePropertyItem.css";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import SetLedger from "../../customMeasure/properties/SetLedger";
import { getEmptyConditionsCaption } from "../helpers/variablePropertyItem.helper";

interface Props {
  formulaNode: FormulaNode;
  state: CustomMeasureReturnType;
  sx?: SxProps<Theme>;
  index: number;
  parentNode: ChartOfAccountsContextItem | null;
}

export const VariablePropertyItem = ({ formulaNode, state, sx, parentNode, index }: Props) => {
  useDeferredDictionaryLoading({
    values: formulaNode.conditions || [],
    updateItem: (field, changes) => state.actions.updateCondition(formulaNode, field, changes),
  });
  const { root, getNode, onSelect, appendChild } = useChartOfAccountsContext();
  const id = getVariablePropertyItemUniqueId(index, formulaNode, parentNode?.identifier);

  const currentNode = useMemo(() => {
    return getNode(id, parentNode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, id, root]);

  const formulaContainerRef = useRef<HTMLDivElement>(null);

  const chartOfAccountItems = useMemo(() => {
    if (formulaNode.table === MeasureUnitTable.Memo) {
      return state.chartOfAccounts?.memo;
    }
    return state.chartOfAccounts?.gl;
  }, [formulaNode.table, state.chartOfAccounts?.gl, state.chartOfAccounts?.memo]);

  const conditionsAreEmpty = !formulaNode.conditions || formulaNode.conditions?.length === 0;

  const isSelectedParameter = useMemo(() => {
    if (state.selectedFormulaParameter === undefined) return false;

    return formulaNode.linkedNodes.some((ln) => findNodeByTokenData(ln, state.selectedFormulaParameter));
  }, [state.selectedFormulaParameter, formulaNode]);

  useEffect(() => {
    if (isSelectedParameter) {
      formulaContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelectedParameter]);

  useEffect(() => {
    if (parentNode) {
      appendChild(id, parentNode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentNode, id]);

  return (
    <Grid container component="li" sx={{ flexWrap: "nowrap" }}>
      <Grid container sx={{ flexDirection: "column", flex: 1, ...sx }}>
        <PropertyItemContainer
          sx={formulaNode.highlighted === true ? { border: "1px solid #00A866" } : {}}
          ref={formulaContainerRef}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: "28px",
              px: 1,
              borderTopLeftRadius: "4px",
              borderTopRightRadius: "4px",
              bgcolor: currentNode?.checked ? "rgba(0, 168, 102, 0.08)" : "white",
            }}
          >
            <Grid
              container
              sx={{
                alignContent: "center",
                alignItems: "center",
                gap: 1.25,
                flexWrap: "nowrap",
              }}
            >
              {currentNode && (
                <Checkbox
                  indeterminate={currentNode.indeterminate === true}
                  checked={currentNode.checked === true}
                  sx={{ width: "28px", height: "28px" }}
                  onChange={(e) => {
                    onSelect(currentNode.identifier, e.target.checked);
                  }}
                />
              )}
              <PropertyName text={formulaNode.text} />
              <SetLedger table={formulaNode.table} />
            </Grid>
          </Box>
          {conditionsAreEmpty && (
            <>
              <Divider />
              <Grid container sx={{ px: 2, height: "30px", alignItems: "center" }}>
                <Typography variant="caption">{getEmptyConditionsCaption(state.isConfigurable)}</Typography>
              </Grid>
            </>
          )}
          {formulaNode.conditions?.length !== 0 &&
            formulaNode.conditions?.map((condition, i) => (
              <Fragment key={condition.meta.name}>
                <Divider />
                <Grid container sx={{ alignItems: "center" }}>
                  <Filter
                    condition={condition}
                    chartOfAccountItems={chartOfAccountItems}
                    parentNode={currentNode}
                    index={i}
                  />
                </Grid>
              </Fragment>
            ))}
        </PropertyItemContainer>
      </Grid>
    </Grid>
  );
};

export default VariablePropertyItem;
