import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import { selectMeasureDatasets } from "../../../../../store/measureDataSetsSlice";
import { useSelector } from "react-redux";
import { selectMeasures } from "../../../../../store/metaDataSlice";
import { MeasureDescriptor } from "../../../../../api/biApi.types";

interface Props {
  table: MeasureUnitTable | undefined;
  editable?: boolean;
  onUpdateTable?: (table: MeasureUnitTable, dimensionNames: string[], measures: MeasureDescriptor[]) => void;
}
export default function SetLedger({ table, editable, onUpdateTable }: Props) {
  const measures = useSelector(selectMeasures);
  const measureDatasets = useSelector(selectMeasureDatasets);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [showLedgerSelection, setLedgerSelection] = useState(false);

  const tableCaption = useMemo(() => (table === MeasureUnitTable.Memo ? "Memo Ledger" : "General Ledger"), [table]);

  const handleDataSetChanged = useCallback(
    (table: MeasureUnitTable) => {
      const dataset = measureDatasets.datasets.find((ds) => ds.table === table);
      if (dataset === undefined) return;
      onUpdateTable?.(table, Object.keys(dataset.values), measures);
    },
    [onUpdateTable, measureDatasets, measures]
  );

  if (!editable) {
    return (
      <Grid item px={1}>
        <Typography color="secondary" variant="body2" noWrap fontWeight={500}>
          {tableCaption}
        </Typography>
      </Grid>
    );
  }

  return (
    <Grid item>
      <Button
        ref={setAnchorEl}
        color="secondary"
        sx={{
          lineHeight: "20px",
          letterSpacing: "0.14px",
          fontSize: "12px",
          textWrap: "nowrap",
          height: "30px",
        }}
        endIcon={<ArrowDropDownRoundedIcon color="action" />}
        onClick={() => setLedgerSelection(true)}
      >
        {tableCaption}
      </Button>

      <Menu anchorEl={anchorEl} open={showLedgerSelection} onClose={() => setLedgerSelection(false)}>
        <MenuItem
          selected={table === MeasureUnitTable.Gl}
          onClick={() => {
            handleDataSetChanged(MeasureUnitTable.Gl);
            setLedgerSelection(false);
          }}
        >
          General Ledger
        </MenuItem>
        <MenuItem
          selected={table === MeasureUnitTable.Memo}
          onClick={() => {
            handleDataSetChanged(MeasureUnitTable.Memo);
            setLedgerSelection(false);
          }}
        >
          Memo Ledger
        </MenuItem>
      </Menu>
    </Grid>
  );
}
