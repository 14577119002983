import { Grid } from "@mui/material";
import { GridRowGroupingModel } from "@mui/x-data-grid-premium";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "../../../../hooks/resizeObserver";
import { Ordering, TabularDataCell } from "../../../../../shared/reporting/api/biClient.types";
import { selectPreviewMode } from "../../../../store/currentReportSlice";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { PreviewDefaultSize } from "../../common/constants/const";
import { SortField, AreaItemType, ValueField } from "../../Types";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { TableField } from "../hooks/TableField";
import { PreviewGridStateType } from "../hooks/useGridStateBuilder";
import { getGroupings } from "./utils/getGrouping";
import { getSorting } from "./utils/getSorting";
import ValidationState from "../../common/ValidationState";
import TabularGrid from "./TabularGrid";
import { drillDownActions } from "../../../../store/drilldownSlice";
import BiErrorCodeComponent from "../../common/bi-error/BiErrorCodeComponent";
import PreviewComponentAlert from "../../../common/PreviewComponentAlert";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { getGroupMetaNames } from "../../common/utilities/fieldsState";

interface Props {
  state: PreviewGridStateType;
}

export default function PreviewComponent({ state }: Props) {
  const dispatch = useDispatch();

  const { fieldsArea, sortingArea, groupingArea } = useFieldsStateContext();
  const previewMode = useSelector(selectPreviewMode);

  const divRef = React.createRef<HTMLDivElement>();
  const size = useResizeObserver(divRef, undefined, previewMode === "maximize" ? undefined : PreviewDefaultSize);

  const fieldsRef = React.useRef(fieldsArea.values);
  fieldsRef.current = fieldsArea.values;
  const sortsRef = React.useRef(sortingArea.values);
  sortsRef.current = sortingArea.values;
  const groupingRef = React.useRef(groupingArea.values);
  groupingRef.current = groupingArea.values;

  const hasConfigurationProvided = React.useMemo(() => fieldsArea.values.length > 0, [fieldsArea.values]);
  const sortingModel = React.useMemo(
    () => getSorting(sortingArea.values, fieldsArea.values),
    [sortingArea.values, fieldsArea.values]
  );

  const handleDrillDown = React.useCallback(
    (rowData: Record<string, TabularDataCell>, columnName: string) => {
      const cell = rowData[columnName];
      if (cell?.drillDownId === undefined) return;
      const measure = fieldsRef.current.find((f) => f.guid === columnName)?.measure;
      if (measure === undefined) return;

      dispatch(drillDownActions.add({ id: generateGuid(), measure, cell, onlyLedger: false }));
    },
    [dispatch]
  );

  const onColumnsSortingChange = React.useCallback(
    (newSorting: { field: string; sort: "asc" | "desc" | null | undefined }[]) => {
      const sortFields = rebuildSortingFields(newSorting, fieldsRef.current, sortsRef.current);
      if (sortFields.length === 0) return;
      sortingArea.setSorting(sortFields, getGroupMetaNames(groupingRef.current, fieldsRef.current));
    },
    [sortingArea]
  );

  const handleGroupingModelChange = React.useCallback(
    (model: GridRowGroupingModel) => {
      const { groupIdsToAdd, groupsToDelete } = getGroupings(model, groupingArea.values);

      groupIdsToAdd.forEach((groupId) => {
        groupingArea.addItem({ name: groupId });
      });

      groupsToDelete.forEach((group) => {
        groupingArea.removeItem(group);
      });
    },
    [groupingArea]
  );

  if (!hasConfigurationProvided) return <></>;

  return (
    <BiErrorCodeComponent errorMessage={state.error}>
      <PreviewComponentAlert
        error={state.error}
        failedMeasures={state.failedMeasures}
        failedGroupTotalMeasures={state.failedGroupTotalMeasures}
      />
      {state.configurationValidity.valid && (
        <Grid
          ref={divRef}
          container
          sx={{ flex: 1, justifyContent: "center" }}
          onDrop={stopEventPropagation} //This workaround is related to this bug: https://github.com/react-dnd/react-dnd/issues/3491
        >
          <TabularGrid
            rows={state.rows}
            fields={fieldsArea.values}
            columns={state.columns}
            grandTotals={state.grandTotals}
            groupTotals={state.groupTotals}
            groupingFields={groupingArea.values}
            sortingModel={sortingModel}
            loading={state.loading}
            size={size}
            groupTotalsLoading={state.groupTotalsLoading}
            onGroupingChanged={handleGroupingModelChange}
            onColumnsSortingChanged={onColumnsSortingChange}
            onColumnsOrderChanged={fieldsArea.updateFieldsOrder}
            onDrillDown={handleDrillDown}
          />
        </Grid>
      )}
      <ValidationState state={state.configurationValidity} measures={getMeasures(fieldsArea.values)} />
    </BiErrorCodeComponent>
  );
}

function rebuildSortingFields(
  newSorts: { field: string; sort: "asc" | "desc" | null | undefined }[],
  fields: TableField[],
  currentSorts: SortField[]
) {
  const currentSortCopy = cloneDeep(currentSorts);

  newSorts.forEach((sort) => {
    const field = fields.find((f) => f.guid === sort.field);
    if (field?.dimension) {
      const currentSort = currentSortCopy.find((s) => s.meta.name === field.dimension!.meta.name);
      const ordering = getOrdering(sort.sort);

      if (currentSort) {
        currentSort.config.ordering = ordering;
      } else {
        currentSortCopy.push({
          meta: field.dimension.meta,
          config: {
            name: field.dimension.meta.name,
            ordering: ordering,
            caption: field.dimension.config.customLabel || field.dimension.meta.caption,
            isGroupField: false,
          },
          areaItemType: AreaItemType.SORTS,
        });
      }
    }
  });

  return currentSortCopy;
}

function getMeasures(fields: TableField[]): ValueField[] {
  return fields
    .filter((f) => f.fieldType === "Measure")
    .map((f) => f.measure)
    .filter((f): f is ValueField => f !== undefined);
}

function stopEventPropagation(e: React.SyntheticEvent) {
  e.stopPropagation();
}

function getOrdering(sort: "asc" | "desc" | null | undefined): Ordering {
  return sort === "asc" ? Ordering.Ascending : Ordering.Descending;
}
