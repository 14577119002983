import { Grid, Typography } from "@mui/material";
import { AmountType, CalculateByField, MeasureUnitTable } from "../../../../../../shared/reporting/api/biClient.types";
import FormatSelect from "../../FormatSelect";
import CalculateByFieldControl from "../../fields/CalculateByFieldControl";
import { CustomMeasureReturnType } from "../hooks/useCustomMeasure";
import AmountTypeFieldControl from "../../fields/AmountTypeFieldControl";
import { useMemo } from "react";
import { getAllVariableNodes } from "../utilities/fillNodes";

interface Props {
  state: CustomMeasureReturnType;
}

export default function SettingsComponent({ state }: Props) {
  const disabledAmountTypeSelection = useMemo(() => {
    return getAllVariableNodes(state.formulaNodes).some((node) => node.table === MeasureUnitTable.Memo);
  }, [state.formulaNodes]);

  return (
    <Grid item sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Typography variant="subtitle1">Settings</Typography>
      <AmountTypeFieldControl
        amountType={disabledAmountTypeSelection ? AmountType.Net : state.amountType || AmountType.Net}
        disabled={disabledAmountTypeSelection}
        onChange={(value) => state.actions.update({ amountType: value })}
      />
      <CalculateByFieldControl
        calculateBy={state.calculateBy || CalculateByField.Lcy}
        onChange={(value) => state.actions.update({ calculateBy: value })}
      />
      <Grid container>
        <FormatSelect
          format={state.format}
          label="Format"
          onUpdateFormatter={(format) => state.actions.update({ format })}
        />
      </Grid>
    </Grid>
  );
}
